const INITIAL_STATE = false;

const sidebar = (state = INITIAL_STATE, action: any) => {
	switch(action.type) {
		case 'SET_SIDEBAR':
            return action.payload;
            break;
        default: 
        	return state;
        	break;
	}
}

export default sidebar;