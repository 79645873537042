import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import { 
	SelectPlan,
	MessagesImage,
	MessagesImage2,
	PlanImage,
	CalendarImage,
	NoPlanImage
} from 'assets/img';
import { PlanService } from 'services';
import { Modal } from 'components';
import ModalSelectPlan from './select-plan';
import moment from 'moment';
import {
	Home1Icon,
	Home2Icon,
	Home3Icon,
	Home4Icon,
	Messages1Icon,
	Messages2Icon,
	Messages3Icon,
	SandClockIcon
} from 'assets/icons';
import { Table, Select } from 'components';
import { 
	Chart, 
	LineController,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip
} from 'chart.js';

Chart.register(
	LineController,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip
);

const TYPES_CHART = {
	MONTH: 1,
	WEEK: 2
}

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Plans extends React.Component<Props> {

	state = {
		plan_users: [],
		loading: true,
		visible: false,
		messages_remaining: 0,
		total_messages: 0,
		dates: [],
		plans: [],
		actual_plan: null,
		data: [],
		header: [
			'Destinatario',
			'Fecha y Hora',
			'Transmisión',
			'Mensaje'
		],
		chart_data: [],
		chart_labels: [],
		type_chart: TYPES_CHART.MONTH,
		chart: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Inicio',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading = false) => {
		// @ts-ignore
		this.state.chart?.destroy();

		await this.setState({
			dates: [],
			plans: []
		});
		const res: any = await PlanService.get({
			withoutLoading,
			type_chart: this.state.type_chart
		});
		let dates: any = [...this.state.dates];
		let plans: any = [...this.state.plans];

		for (let i = 0; i < res.plan_users.length; i++) {
			if (plans.map((i: any) => i.id).indexOf(res.plan_users[i].plan_id) == -1) {
				plans.push({
					id: res.plan_users[i].plan_id,
					name: res.plan_users[i].plan?.name || ''
				});
			}

			const index = dates.map((i: any) => i.expiration_date).indexOf(res.plan_users[i].expiration_date);
			if (index == -1) {
				dates.push({
					expiration_date: res.plan_users[i].expiration_date,
					messages_remaining: res.plan_users[i].messages_remaining
				});
			}
			else {
				dates[index] = {
					...dates[index],
					messages_remaining: dates[index].messages_remaining + res.plan_users[i].messages_remaining
				}
			}
		}

		this.setState({
			plan_users: res.plan_users || [],
			loading: false,
			messages_remaining: res.messages_remaining,
			total_messages: res.total_messages,
			dates,
			plans,
			actual_plan: res.actual_plan,
			data: res.messages || [],
			chart_data: res.chart_data || [],
			chart_labels: res.chart_labels || []
		},() => {
			try {
				this.setState({
					// @ts-ignore
					chart: new Chart(document.getElementById("chart"), {
						type: 'line',
						data: {
						  labels: this.state.chart_labels || [],
						  datasets: [
						    {
						      label: 'Dataset',
						      data: this.state.chart_data || [],
						      borderColor: '#ff7290',
						      backgroundColor: 'rgba(255,114,144,0.5)',
						      pointStyle: 'circle',
						      pointRadius: 7,
						      pointHoverRadius: 10
						    }
						  ]
						},
						options: {
						    scales: {
						        y: {
					                beginAtZero: true
					            }
						    },
						    plugins: {
					            tooltip: {
					                callbacks: {
					                    label: function(context: any) {
					                        let label = "Mensajes: ";

					                        if (context.parsed.y !== null) {
					                            label += context.parsed.y;
					                        }

					                        return label;
					                    }
					                }
					            }
					        }
						}
					})
				});
			}
			catch(e) {
				console.log(e);
			}
		});
	}

	active = () => {
		this.setState({
			visible: true
		});
	}

	onClose = () => {
		this.setState({
			visible: false
		},() => this.load(true));
	}
	
	render() {
		const { loading, visible } = this.state;
		const plan_users: any = this.state.plan_users;
		const dates: any = this.state.dates;
		const plans: any = this.state.plans;
		const actual_plan: any = this.state.actual_plan;

		return (
			<div id="plan">
				<Modal
		          className="modal-view-plans"
		          visible={ visible }
		          title="Contratar plan"
		          onClose={ () => this.onClose() }
		        >
		          <ModalSelectPlan
		            onClose={ () => this.onClose() } />
		      	</Modal>

				{
					!loading && plan_users.length == 0 && (
						<div className="container-white">
							<p>No tienes un plan activo</p>
							<h3>¡Empieza ahora a disfrutar de { process.env.REACT_APP_NAME }!</h3>
							<img src={ NoPlanImage } className="no-plan-image" />
							<button className="btn btn-active" onClick={ () => this.active() }>
								Activar
							</button>
						</div>
					)
				}
				{
					!loading && plan_users.length > 0 && (
						<>
							<div className="row">
								<div className="col-md-7">
									<Select
										color="white"
										placeholder="Seleccionar"
										name="type_chart"
										onChange={ (e: any) => {
											this.setState({
												type_chart: e.target.value
											},() => this.load());
										} }
										value={ this.state.type_chart }
										options={ [
											{ value: TYPES_CHART.MONTH.toString(), label: 'Mes' },
											{ value: TYPES_CHART.WEEK.toString(), label: 'Semana' },
										] } />
									<canvas id="chart"></canvas>
								</div>
								<div className="col-md-5">
									<div className="item orange">
										<img src={ Messages3Icon } />
										<div>
											<div className="value">
												<p>{ actual_plan?.plan?.name }</p>
											</div>
											<p className="name">Plan Seleccionado</p>
										</div>
									</div>
									<div className="item green">
										<img src={ Messages2Icon } />
										<div>
											<div className="value">
												<p>{ this.state.total_messages }</p>
											</div>
											<p className="name">Mensajes totales</p>
										</div>
									</div>
									<div className="item blue">
										<img src={ Messages1Icon } />
										<div>
											<div className="value">
												<p>{ this.state.messages_remaining }</p>
											</div>
											<p className="name">Mensajes restantes</p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<h3>Fecha de vencimiento</h3>
									<div className="item purple">
										<div className="text-center" style={{ width: '100%' }}>
											<img src={ CalendarImage } className="principal-image" />
											<div className="container-icon">
												<img src={ SandClockIcon } />
												<p className="name">Fechas de vencimiento</p>
											</div>
											{
												dates.map((item: any) => {
													return (
														<div className="value small">
															<p>{ moment(item?.expiration_date).format('DD/MM/YYYY') } ({ item?.messages_remaining })</p>
														</div>
													)
												})
											}
											<button className="btn btn-active" onClick={ () => this.active() }>
												Cambiar plan
											</button>
										</div>
									</div>
								</div>
								<div className="col-md-8">
									<h3>SMS Enviados</h3>
									<Table 
										tableTop={
											<div className="row row-btn">
												<div className="col-md-6">
													<button className="btn btn-active" onClick={ () => {
														this.props.history.replace({
															pathname: '/client/campaign',
															state: {
																create_campaign: true
															}
														});
													} }>
														Crear campaña
													</button>
												</div>
												<div className="col-md-6 text-right">
													<a href="#" className="view-more" onClick={ (e: any) => {
														e.preventDefault();
														this.props.history.replace('/client/reports/messages');
													} }>
														Ver más
													</a>
												</div>
											</div>
										}
										title="SMS Enviados" data={ this.state.data.length } header={ this.state.header }>
										{ this.state.data.map((i: any,index: number) => {
											return (
												<tr key={ index }>
													<td>{ i.to }</td>
													<td>{ moment(i.created_at).format('DD/MM/YYYY HH:mm') }</td>
													<td>{ i?.campaign?.number ? ('Campaña Nº' + i.campaign?.number) : 'API' }</td>
													<td>{ i.content }</td>
												</tr>
											)
										}) }
									</Table>
								</div>
							</div>
						</>
					)
				}
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Plans);