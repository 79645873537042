import React from 'react';
import { Input, Button } from 'components';
import { Globals, Constants, SocketEvents } from 'utils';
import { connect } from 'react-redux';
import { AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router';
import { ArrowLeft } from 'assets/icons';
import { BgLogin } from 'assets/img';
import { Link } from 'react-router-dom';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Register extends React.Component<Props> {

	state = {
		form: {
			name: '',
			lastname: '',
			password: '',
			password_confirmation: '',
			phone: '',
			email: ''
		}
	}

	componentDidMount() {
		if (this.props.user) {
			this.props.history.replace('/');
		}

		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Registrarme',
				onBack: null
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		const res: any = await AuthService.register({
			...this.state.form
		});
		this.props.history.replace('/login');
		Globals.showSuccess("Se ha registrado correctamente");
		if (res.user) {
			await this.props.dispatch({
				type: 'SET_ACCESS_TOKEN',
				payload: res.access_token
			});
			await this.props.dispatch({
				type: 'SET_USER',
				payload: res.user
			});
			switch(res.user?.level_id) {
				case Constants.LEVELS.ADMIN:
					this.props.history.replace("/admin/users");
				break;

				case Constants.LEVELS.CLIENT:
					this.props.history.replace("/client/home");
				break;

				default:
					this.props.history.replace("/profile");
				break;
			}
		}
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}
	
	render() {
		return (
			<div id="register">
				<div className="row container-form">
					<div className="col-lg-6 container-bg text-center" style={{ backgroundImage: `url(${ BgLogin })` }}>
						
					</div>
					<div className="col-lg-6" onSubmit={ this.submit }>
						<div className="row row-title">
							<div className="col-2">
								<Link to="/login">
									<img src={ ArrowLeft } className="arrow-left-green" />
								</Link>
							</div>
							<div className="col-8">
								<h3 className="title-green">Registrarme</h3>
							</div>
						</div>
						<form className="container-input">
							<Input
								placeholder="Nombre"
								name="name"
								onChange={ this.change } />
							<Input
								placeholder="Apellido"
								name="lastname"
								onChange={ this.change } />
							<Input
								placeholder="Correo electrónico"
								name="email"
								onChange={ this.change } />
							<Input
								type="number"
								placeholder="Teléfono"
								name="phone"
								onChange={ this.change } />
							<Input
								placeholder="Contraseña"
								type="password" 
								name="password"
								onChange={ this.change } />
							<Input
								placeholder="Repetir Contraseña"
								type="password" 
								name="password_confirmation"
								onChange={ this.change } />
							<div className="text-center">
								<Button type="submit">
									Registrar
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Register);