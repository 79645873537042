export default {
	BASE_STORAGE: process.env.REACT_APP_BASE_STORAGE,
	BASE_API: process.env.REACT_APP_BASE_API,
	BASE_PUBLIC: process.env.REACT_APP_BASE_PUBLIC,
	TIMEZONE: 'America/Caracas',
	LEVELS: {
		ADMIN: 1,
		MODERATOR: 2,
		CLIENT: 3
	},
	USER: {
		VERIFIED: {
			PENDING: 0,
			APPROVED: 1,
			REJECTED: 2,
		},
		STATUS: {
			PENDING: 0,
			ACTIVE: 1,
			INACTIVE: 2,
		}
	},
	MODULES: {
		
	},
	MESSAGE_STATUS: {
		SENT: 1,
		FAILED: 0
	},
	MESSAGE_TYPES: {
		API: 1,
		CAMPAIGN: 2
	},
	MAX_CHARACTERS: 129,
	PHONECODES: ['0412','0414','0424','0416','0426'],
	AUTORENEWAL: {
		YES: 1,
		NO: 0
	}
}