import React from 'react';
import { Input, Button, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { UserService } from 'services';
import { CheckWhiteIcon } from 'assets/icons';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalUpdateUser extends React.Component<Props> {

	state = {
		form: {
			name: '',
			lastname: '',
			password: '',
			phone: '',
			email: '',
			autorenewal: Constants.AUTORENEWAL.NO
		},
	}

	componentDidMount() {
		if (this.props.item) {
			const item: any = this.props.item;

			this.setState({
				form: {
					...this.state.form,
					name: item?.name || '',
					lastname: item?.lastname || '',
					email: item?.email || '',
					phone: item?.phone || '',
					autorenewal: item?.autorenewal || Constants.AUTORENEWAL.NO
				}
			});
		}
	}

	submit = async (e: any) => {
		e.preventDefault();
		await UserService.admin.update({
			...this.state.form,
			user_id: this.props.item?.id
		});
		this.props.onClose();
		Globals.showSuccess("Se ha modificado el usuario correctamente");
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="modal-update-user">
				<form onSubmit={ this.submit }>
					<div className="row">
						<div className="col-md-12">
							<Input
								value={ this.state.form.name }
								label="Nombre"
								name="name"
								onChange={ this.change } />
						</div>
						<div className="col-md-12">
							<Input
								value={ this.state.form.lastname }
								label="Apellido"
								name="lastname"
								onChange={ this.change } />
						</div>
						<div className="col-md-12">
							<Input
								value={ this.state.form.email }
								label="Correo electrónico"
								name="email"
								onChange={ this.change } />
						</div>
						<div className="col-md-12">
							<Input
								value={ this.state.form.phone }
								type="number"
								label="Teléfono"
								name="phone"
								onChange={ this.change } />
						</div>
						<div className="col-md-12">
							<Input
								label="Cambiar Contraseña (Opcional)"
								type="password" 
								name="password"
								onChange={ this.change } />
						</div>
					</div>

					<div className="container-check" onClick={ () => this.setState({
						form: {
							...this.state.form,
							autorenewal: !this.state.form.autorenewal
						}
					}) }>
						<div className={ `check ${ this.state.form.autorenewal == Constants.AUTORENEWAL.YES ? 'active' : '' }` }>
							{
								this.state.form.autorenewal == Constants.AUTORENEWAL.YES && (
									<img src={ CheckWhiteIcon } />
								)
							}
						</div>
						<p>Autorenovar cuenta</p>
					</div>

	      			<div className="text-center">
						<Button type="submit">
							Guardar
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalUpdateUser;