import React from 'react';
import { Input, Button, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { PlanService, UserService } from 'services';
import moment from 'moment';
import {
	Home1Icon,
	Home2Icon,
	Home3Icon,
	Home4Icon,
	TrashIcon
} from 'assets/icons';
import { 
	SelectPlan,
	MessagesImage,
	MessagesImage2,
	PlanImage,
	CalendarImage,
	NoPlanImage
} from 'assets/img';

interface Props {
	onClose?: any;
	user_id?: number | null;
}

class SelectPlanComponent extends React.Component<Props> {

	state = {
		plans: [],
		plan_users: [],
		loading: true,
		visible: false,
		messages_remaining: 0,
		total_messages: 0,
		dates: [],
		plans_selected: [],
		actual_plan: null
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		await this.setState({
			dates: [],
			plans: []
		});
		const res: any = await PlanService.getPlanList({
			user_id: this.props.user_id
		});
		let dates: any = [...this.state.dates];
		let plans: any = [...this.state.plans];

		for (let i = 0; i < res.plan_users.length; i++) {
			if (plans.map((i: any) => i.id).indexOf(res.plan_users[i].plan_id) == -1) {
				plans.push({
					id: res.plan_users[i].plan_id,
					name: res.plan_users[i].plan?.name || ''
				});
			}

			const index = dates.map((i: any) => i.expiration_date).indexOf(res.plan_users[i].expiration_date);
			if (index == -1) {
				dates.push({
					expiration_date: res.plan_users[i].expiration_date,
					messages_remaining: res.plan_users[i].messages_remaining,
					plans: [{
						id: res.plan_users[i]?.id,
						plan_id: res.plan_users[i].plan_id,
						name: res.plan_users[i].plan?.name,
						messages_remaining: res.plan_users[i].messages_remaining
					}]
				});
			}
			else {
				dates[index] = {
					...dates[index],
					messages_remaining: dates[index].messages_remaining + res.plan_users[i].messages_remaining,
					plans: [...dates[index].plans,{
						id: res.plan_users[i]?.id,
						plan_id: res.plan_users[i].plan_id,
						name: res.plan_users[i].plan?.name,
						messages_remaining: res.plan_users[i].messages_remaining
					}]
				}
			}
		}
		this.setState({
			plans: res.plans || [],
			plan_users: res.plan_users || [],
			loading: false,
			messages_remaining: res.messages_remaining,
			total_messages: res.total_messages,
			dates,
			plans_selected: plans,
			actual_plan: res.actual_plan
		});
	}

	setActive = (item: any, index: number) => {
		let plans: any = [...this.state.plans].map((item: any) => {
			item.active = false;
			return item;
		});
		plans[index].active = !plans[index].active;
		this.setState({
			plans
		});
	}

	submit = async () => {
		const plan: any = this.state.plans.find((item: any) => item.active);

		await PlanService.admin.saveUser({
			plan_id: plan?.id,
			to_user_id: this.props.user_id
		});
		Globals.showSuccess("Se ha modificado el plan");
		this.load();
	}

	deletePlan = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar los SMS seleccionados?',async () => {
			await UserService.admin.deletePlanUser({
				plan_user_id: item?.id
			});
			Globals.showSuccess("Se han eliminado los SMS del usuario");
			this.load();
		});
	}
	
	render() {
		const plan: any = this.state.plans.find((item: any) => item.active);
		const { dates, plans_selected, loading } = this.state;
		const actual_plan: any = this.state.actual_plan;

		return (
			<div id="modal-view-plans">
				{
					!loading && (
						<div className="row row-plan">
							<div className="col-md-6">
								<div className="item black">
									<div className="container-icon">
										<img src={ Home1Icon } />
										<p className="name">Mensajes restantes</p>
									</div>
									<div className="value">
										<p>{ this.state.messages_remaining }</p>
									</div>
									<img src={ MessagesImage } className="principal-image" />
								</div>
							</div>
							<div className="col-md-6">
								<div className="item blue">
									<div className="container-icon">
										<img src={ Home2Icon } />
										<p className="name">Mensajes totales</p>
									</div>
									<div className="value">
										<p>{ this.state.total_messages }</p>
									</div>
									<img src={ MessagesImage2 } className="principal-image" />
								</div>
							</div>
							<div className="col-md-6">
								<div className="item violet">
									<div className="container-icon">
										<img src={ Home3Icon } />
										<p className="name">Plan Seleccionado</p>
									</div>
									<div className="value small">
										<p>{ actual_plan?.plan?.name }</p>
									</div>
									<img src={ PlanImage } className="principal-image" />
								</div>
							</div>
							<div className="col-md-6">
								<div className="item green">
									<div className="container-icon">
										<img src={ Home4Icon } />
										<p className="name">Fecha de vencimiento</p>
									</div>
									{
										dates.map((item: any) => {
											return (
												<div className="value small">
													<p className="margin-bottom">{ moment(item?.expiration_date).format('DD/MM/YYYY') } ({ item?.messages_remaining })</p>
													<ul>
														{
															item.plans.map((i: any) => {
																return (
																	<li>
																		<p>{ i.name } ({ i.messages_remaining })</p>
																		<a className="link-icon" href="#" onClick={ (e: any) => this.deletePlan(e,i) }>
																			<img src={ TrashIcon } className="delete-plan" />
																		</a>
																	</li>
																)
															})
														}
													</ul>
												</div>
											)
										})
									}
									<img src={ CalendarImage } className="principal-image" />
								</div>
							</div>
						</div>
					)
				}

				<h1>Asignar plan</h1>
				<hr />

				<div className="row">
					{
						this.state.plans.map((item: any,index: number) => {
							return (
								<div className="col-md-4">
									<div className={ `item-plan pointer ${ item.active ? 'active' : '' }` } onClick={ () => this.setActive(item,index) }>
										<div className="row">
											<div className="col-md-7">
												<p className="name">{ item?.name }</p>
												<p className="messages">{ item?.quantity } SMS</p>
											</div>
											<div className="col-md-5 text-right">
												<p className="price">{ Globals.formatMiles(item?.price) }</p>
											</div>
										</div>
										<p className="small no-margin"><strong>Restricciones:</strong> Prohibidos contenidos políticos, loterías, apuestas, hípica, bebidas alcohólicas, chats personales y lenguaje obsceno.</p>
									</div>
								</div>
							)
						})
					}
				</div>
				{
					plan != null && (
						<div className="text-center">
							<Button type="submit" onClick={ () => this.submit() }>
								Continuar
							</Button>
						</div>
					)
				}
			</div>
		)
	}
}

export default SelectPlanComponent;