import React from 'react';
import { Input, Button, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { PlanService } from 'services';
import { ArrowLeft } from 'assets/icons';

interface Props {
	onClose?: any;
}

class SelectPlan extends React.Component<Props> {

	state = {
		plans: [],
		form: {
			phone_code: '412',
			phone: '',
			reference: '',
			bank_code: ''
		},
		bnc_document: '',
		bnc_bank: '',
		bnc_phone: '',
		banks: [],
		phone_codes: Constants.PHONECODES,
		conversion: 0,
		plan_selected: null
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await PlanService.getPlanList();
		this.setState({
			plans: res.plans || [],
			bnc_phone: res.bnc_phone || '',
			bnc_bank: res.bnc_bank || '',
			bnc_document: res.bnc_document || '',
			banks: res.banks || [],
			conversion: res.conversion || 0
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	setActive = (item: any, index: number) => {
		let plans: any = [...this.state.plans].map((item: any) => {
			item.active = false;
			return item;
		});
		plans[index].active = !plans[index].active;
		this.setState({
			plans
		});
	}

	submit = async () => {
		if (!this.state.form.bank_code) {
			Globals.showError('Por favor, ingrese el banco emisor');
			return;
		}

		if (!this.state.form.phone_code) {
			Globals.showError('Por favor, ingrese el código de teléfono');
			return;
		}

		if (!this.state.form.phone) {
			Globals.showError('Por favor, ingrese el número de teléfono');
			return;
		}

		if (!this.state.form.reference) {
			Globals.showError('Por favor, ingrese el número de referencia');
			return;
		}

		await PlanService.save({
			// @ts-ignore
			plan_id: this.state.plan_selected?.id,
			...this.state.form
		});
		Globals.showSuccess("Se ha contratado el plan");
		this.props.onClose();
	}
	
	render() {
		const plan: any = this.state.plans.find((item: any) => item.active);
		const plan_selected: any = this.state.plan_selected;

		return (
			<div id="modal-view-plans">
				{
					plan_selected == null && (
						<>
							<div className="row">
								{
									this.state.plans.map((item: any,index: number) => {
										return (
											<div className="col-md-4">
												<div className={ `item-plan pointer ${ item.active ? 'active' : '' }` } onClick={ () => this.setActive(item,index) }>
													<div className="row">
														<div className="col-md-7">
															<p className="name">{ item?.name }</p>
															<p className="messages">{ item?.quantity } SMS</p>
														</div>
														<div className="col-md-5 text-right">
															<p className="price">{ Globals.formatMiles(item?.price) }</p>
														</div>
													</div>
													<p className="small no-margin"><strong>Restricciones:</strong> Prohibidos contenidos políticos, loterías, apuestas, hípica, bebidas alcohólicas, chats personales y lenguaje obsceno.</p>
												</div>
											</div>
										)
									})
								}
							</div>
							<div className="text-center">
								<Button type="submit" onClick={ () => {
									this.setState({
										plan_selected: plan
									});
								} }>
									Continuar
								</Button>
							</div>
						</>
					)
				}
				{
					plan_selected != null && (
						<>
							<div className="row">
								<div className="col-md-6">
									<div className="item-plan active">
										<div className="row">
											<div className="col-md-7">
												<div style={{ display: 'flex' }}>
													<img src={ ArrowLeft } className="back-icon" onClick={ () => {
														this.setState({
															plan_selected: null
														});
													} } />
													<div>
														<p className="name">{ plan_selected?.name }</p>
														<p className="messages">{ plan_selected?.quantity } SMS</p>
													</div>
												</div>
											</div>
											<div className="col-md-5 text-right">
												<p className="price">{ Globals.formatMiles(plan_selected?.price) }</p>
											</div>
										</div>
										<p className="small no-margin"><strong>Restricciones:</strong> Prohibidos contenidos políticos, loterías, apuestas, hípica, bebidas alcohólicas, chats personales y lenguaje obsceno.</p>
									</div>
								</div>
							</div>
							<div className="payment-data">
								<p className="title"><strong>Datos de Pago</strong></p>
								<p><strong>Banco:</strong> { this.state.bnc_bank }</p>
								<p><strong>RIF:</strong> { this.state.bnc_document }</p>
								<p><strong>Teléfono:</strong> { this.state.bnc_phone }</p>
								<p><strong>Monto a pagar:</strong> { Globals.formatMiles((this.state.conversion * plan_selected?.price).toFixed(2),true,'Bs.') }</p>
							</div>
							<div className="row">
								<div className="col-md-6">
									<Select
										color="white"
										placeholder="Seleccionar"
										label="Banco Emisor"
										name="bank_code"
										onChange={ (e: any) => this.change(e) }
										value={ this.state.form.bank_code }
										options={ this.state.banks?.map((i: any) => {
											return {
												value: i.code,
												label: i.name
											}
										}) || [] } />
								</div>
								<div className="col-md-6">
									<p className="label">Teléfono</p>
									<div className="container-phone">
										<Select
											color="white"
											placeholder="Cód."
											name="phone_code"
											onChange={ (e: any) => this.change(e) }
											value={ this.state.form.phone_code }
											options={ Constants.PHONECODES.map((i: any) => {
												return {
													value: i.replace('0',''),
													label: i
												}
											}) || [] } />
										<Input
											type="number"
											value={ this.state.form.phone }
											name="phone"
											placeholder="Teléfono"
											onChange={ this.change } />
									</div>
								</div>
								<div className="col-md-6">
									<Input
										type="number"
										label="Número de Referencia"
										value={ this.state.form.reference }
										name="reference"
										placeholder="Referencia"
										onChange={ this.change } />
								</div>
							</div>
							<div className="text-center">
								<Button type="submit" onClick={ () => this.submit() }>
									Continuar
								</Button>
							</div>
						</>
					)
				}
			</div>
		)
	}
}

export default SelectPlan;